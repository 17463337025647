<template>
  <div>
    <mypage>
      <template v-slot:default>
        <face-to-face-table filterPic />
      </template>
    </mypage>
  </div>
</template>

<script>
import Mypage from '../Mypage.vue';
import FaceToFaceTable from '../../customer/response/FaceToFaceCustomer/FaceToFaceTable.vue';

export default {
  name: 'Meeting',
  components: { 
    Mypage,
    FaceToFaceTable
  }
}
</script>